jQuery(document).ready(function($) {
	
	
    /* !----------------- SCROLL MAGIC ------------------! */
	
	if( ($('body').hasClass('desktop') == true) ){}
		
		var controller = new ScrollMagic.Controller();		
		
		/* 
		 *
		 * -------------------------- MENU SQUISH
		 *
		 */
		var scene = new ScrollMagic.Scene({triggerElement: ".menu-trigger"})
			.setClassToggle(".main-header", "squish")
			.setPin(".main-header")
			//.addIndicators() // add indicators (requires plugin)
			.addTo(controller);
	
 
    
    
    	/* !----------------- SWIPER ------------------! */
	var swiper = new Swiper('.intro-swiper.has-slides', {
		loop: false,
		slidesPerView : 1,
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		}
	});
   
	var swiper = new Swiper('.card-slider', {
		loop: false,
		slidesPerView : 1,
		pagination: {
        el: '.swiper-pagination',
        type: 'fraction',
        renderFraction: function (currentClass, totalClass) {
		return '<span class="' + currentClass + '"></span>' +
             ' of ' +
             '<span class="' + totalClass + '"></span>';
  		}
      	},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		}		
	});
	

	$('.card-slider2').each(function(){
	    var $this = $(this);
	    var swiper = new Swiper(this, {
        	loop: false,
			slidesPerView : 3,
			slidesPerGroup: 3, 
			spaceBetween: 20,
			pagination: {
		        el: $this.prev().find(".card2-page .swiper-pagination"),
		        type: 'fraction',
		        renderFraction: function (currentClass, totalClass) {
				return '<span class="' + currentClass + '"></span>' +
		             ' of ' +
		             '<span class="' + totalClass + '"></span>';
		  		}
		    },
			// Responsive breakpoints
			breakpoints: {
			    // when window width is <= 640px
			    640: {
			      slidesPerView: 1,
			      slidesPerGroup: 1, 
			      spaceBetween: 10
			    }
			},
			navigation: {
				//nextEl: $this.find(".swiper-button-next")[0],
				//prevEl: $this.find(".swiper-button-prev")[0],
				 nextEl: $this.next().find(".swiper-button-next"),
				 prevEl: $this.next().find(".swiper-button-prev"),
			}
    	})
    });

		
	
	$('.navbar-collapse a').on('click', function(e){
		$('.navbar-toggle').trigger('click')
	});
	
	$(".chosen-select").chosen();
	$('#go_to_region_button').on( 'click', function(){
		var link = $('#go_to_region_link').val();
		window.location.href = link;
	})


	$('#go_to_region_button').on( 'click', function(){
		var link = $('#go_to_region_link').val();
		window.location.href = link;
	})
	
		/* !----------------- IMAGE GRID ------------------! */
	$('.image-grid-modal-gallery').on('shown.bs.modal', function( e ){
		var gallerySwiper = new Swiper('.image-grid-gallery-swiper', {
			grabCursor: true,
			pagination: {
	        	el: '.swiper-pagination',
				clickable: true,
				renderBullet: function (index, className) {
					return '<span class="' + className + '"></span>';
	        	},
	    	}
		});
		
		gallerySwiper.slideTo( $(e.relatedTarget).data('initialslide') )
	});	
	
	/**  Pop the iframe srcs off to stop the video * */
	( function(){
		var reset_iframes = function(){
			var frames = $('.modal--video').find( 'iframe' );
			$(frames).each( function( e ){
				var src = $(this).attr('src');
				$(this).attr( 'src' , '' );
				$(this).attr( 'data-src', src );
			});
		}	
		var set_iframe_src = function(){
			var frames = $('.modal--video').find( 'iframe' );
			$(frames).each( function( e ){
				var src = $(this).attr('data-src');
				$(this).attr( 'src', src );
			});
		}
		
		$(".modal--video").on("shown.bs.modal", function(e) {
			var frames;
		    var modal_id = $(this).attr('id');
			frames = $("#"+modal_id).find("iframe"),
			$(frames).each(function(e) {
				var src = $(this).attr("data-src");
				$(this).attr("src", src);
			})
		})
/*
		$('.modal--video').on('hidden.bs.modal', function( e ){
			reset_iframes();
			var video = $(this).find('video');
			if( video.length > 0 ){
				video[0].currentTime = 0;
				video[0].pause();
			}
		});
		$('.modal--video').on('shown.bs.modal', function( e ){
			set_iframe_src();
		});	
*/
		$('.modal--video').on('hidden.bs.modal', function( e ){
			var frames;
		    var modal_id = $(this).attr('id');
			frames = $("#"+modal_id).find("iframe"),
			$(frames).each(function(e) {
				var src = $(this).attr('src');
				$(this).attr( 'src' , '' );
				$(this).attr( 'data-src', src );
			})
			var video = $(this).find('video');
			if( video.length > 0 ){
				video[0].currentTime = 0;
				video[0].pause();
			}
		});

	})();

});
