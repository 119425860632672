/**
*
* This class is loaded by default, and called in the footer
* If there is a page object available that will also be called from the footer after global. 
* 
* 
**/
var global = (function( global_options, default_global_options ){

	/** Constructor globals **/	
	var options	 

	init();

	/**
	 * init function.
	 * 
	 * @access public
	 * @return void
	 */
	function init(){
		$('body').css('opacity', 1)
		//console.log('no global ');
		var options = $.extend({}, global_options, default_global_options);
		
		enable_default_functions( options )
				
		enable_default_bootstrap( );

		if( typeof( loader_content ) ==  'function' ){
			$('[data-loader]').each(function(){
				$(this).html(loader_content($(this).data('loader')))			
			});
		}		

		if (typeof console === "undefined") console = { log: function() { } };

	}//init

	 function enable_default_functions ( options ){

		if( typeof( encode_emails ) ==  'function' )
			encode_emails( options );
		if( typeof( clickable ) ==  'function' )
			clickable( options );
		if( typeof( mailchimp_simple_modal ) ==  'function' )
			mailchimp_simple_modal( options );
		if( typeof( smooth_scroll ) ==  'function' )
			smooth_scroll( options );
		if( typeof( jsfill ) ==  'function' )
			jsfill( options );
		if( typeof( form_submitter ) ==  'function' ) 					
			form_submitter( options );
		if( typeof( disable_mouse_wheel_number_input ) ==  'function' ) 					
			disable_mouse_wheel_number_input( options );
		if( typeof( browser_ie_check ) ==  'function' ) 					
			browser_ie_check( options );
					
	}
	
	function enable_default_bootstrap(){
		if(typeof($.fn.tooltip) != 'undefined')
			$('.desktop [data-toggle="tooltip"]').tooltip();			
		if(typeof($.fn.popover) != 'undefined')
			$('.desktop [data-toggle="popover"]').popover();
		if(typeof($.fn.modal) != 'undefined')
			$('[data-autoload="true"]').modal('show');
			
	}



	//return 
	return {
		init : function(){
			init();
		}
	}
	
});
